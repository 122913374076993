<template>
  <div :class="wrpCls">
    <notice-icon :noticeCount="noticeCount" :class="prefixCls"></notice-icon>
    <avatar-dropdown :menu="showMenu" :current-user="currentUser" :class="prefixCls" />
    <!--select-lang :class="prefixCls" /-->
  </div>
</template>

<script>
import AvatarDropdown from './AvatarDropdown'
// import SelectLang from '@/components/SelectLang'
import NoticeIcon from '@/components/NoticeIcon/NoticeIcon'

export default {
  name: 'RightContent',
  components: {
    AvatarDropdown,
    // SelectLang,
    NoticeIcon
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action'
    },
    isMobile: {
      type: Boolean,
      default: () => false
    },
    topMenu: {
      type: Boolean,
      required: true
    },
    theme: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showMenu: true,
      currentUser: {},
      dataList: [],
      noticeCount: 0
    }
  },
  computed: {
    wrpCls () {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${(this.isMobile || !this.topMenu) ? 'light' : this.theme}`]: true
      }
    }
  },
  created () {
    setInterval(() => {
      this.userNoticeCountApi()
    }, 60000)
  },
  methods: {
    userNoticeCountApi () {
      const _this = this
      _this.$store.dispatch('UserNoticeCountApi').then(res => {
        if (res.errorCode === 0) {
          _this.noticeCount = res.result.data
        }
        console.log('userNoticeCountApi', res)
      })
    }
  },
  mounted () {
    setTimeout(() => {
      this.currentUser = this.$store.state.user
      console.log('currentUser', this.currentUser)
      this.userNoticeCountApi()
    }, 1500)
  }
}
</script>
