import request from '@/utils/request'
const baseURL = '/baseapi'
const NoticeApi = {
  NoticeList: '/admin/notice/noticeList',
  SetNoticeCheckStatus: '/admin/notice/setNoticeCheckStatus',
  NoticeCondition: '/admin/notice/condition',
  DelNotice: '/admin/notice/delNotice',
  RecoveryNotice: '/admin/notice/recoveryNotice',
  SaveNotice: '/admin/notice/saveNotice',
  NoticeInfo: '/admin/notice/noticeInfo',
  VerifyList: '/admin/notice/verifyList',
  MyNotice: '/admin/notice/myNotice',
  UserNoticeCount: '/admin/notice/userNoticeCount',
  UserNoticeList: '/admin/notice/userNoticeList',
  SetIsReaded: '/admin/notice/setIsReaded'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function noticeList (parameter) {
  return request({
    baseURL: baseURL,
    url: NoticeApi.NoticeList,
    method: 'post',
    data: parameter
  })
}

export function setNoticeCheckStatus (parameter) {
  return request({
    baseURL: baseURL,
    url: NoticeApi.SetNoticeCheckStatus,
    method: 'post',
    data: parameter
  })
}

export function noticeCondition () {
  return request({
    baseURL: baseURL,
    url: NoticeApi.NoticeCondition,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function delNotice (parameter) {
  return request({
    baseURL: baseURL,
    url: NoticeApi.DelNotice,
    method: 'post',
    data: parameter
  })
}

export function recoveryNotice (parameter) {
  return request({
    baseURL: baseURL,
    url: NoticeApi.RecoveryNotice,
    method: 'post',
    data: parameter
  })
}

export function saveNotice (parameter) {
  return request({
    baseURL: baseURL,
    url: NoticeApi.SaveNotice,
    method: 'post',
    data: parameter
  })
}

export function noticeInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: NoticeApi.NoticeInfo,
    method: 'post',
    data: parameter
  })
}

export function verifyList (parameter) {
  return request({
    baseURL: baseURL,
    url: NoticeApi.VerifyList,
    method: 'post',
    data: parameter
  })
}

export function myNotice (parameter) {
  return request({
    baseURL: baseURL,
    url: NoticeApi.MyNotice,
    method: 'post',
    data: parameter
  })
}

export function userNoticeCount (parameter) {
  return request({
    baseURL: baseURL,
    url: NoticeApi.UserNoticeCount,
    method: 'post',
    data: parameter
  })
}

export function userNoticeList (parameter) {
  return request({
    baseURL: baseURL,
    url: NoticeApi.UserNoticeList,
    method: 'post',
    data: parameter
  })
}

export function setIsReaded (parameter) {
  return request({
    baseURL: baseURL,
    url: NoticeApi.SetIsReaded,
    method: 'post',
    data: parameter
  })
}
