// eslint-disable-next-line
import * as loginService from '@/api/login'
// eslint-disable-next-line
import { BasicLayout, BlankLayout, PageView, RouteView } from '@/layouts'

// 前端路由表
const constantRouterComponents = {
  // 基础页面 layout 必须引入
  BasicLayout: BasicLayout,
  BlankLayout: BlankLayout,
  RouteView: RouteView,
  PageView: PageView,
  '403': () => import(/* webpackChunkName: "error" */ '@/views/exception/403'),
  '404': () => import(/* webpackChunkName: "error" */ '@/views/exception/404'),
  '500': () => import(/* webpackChunkName: "error" */ '@/views/exception/500'),

  // 你需要动态引入的页面组件
  Workplace: () => import('@/views/dashboard/Workplace'),
  Analysis: () => import('@/views/dashboard/Analysis'),

  // form
  BasicForm: () => import('@/views/form/basicForm'),
  StepForm: () => import('@/views/form/stepForm/StepForm'),
  AdvanceForm: () => import('@/views/form/advancedForm/AdvancedForm'),

  // list
  TableList: () => import('@/views/list/TableList'),
  StandardList: () => import('@/views/list/BasicList'),
  CardList: () => import('@/views/list/CardList'),
  SearchLayout: () => import('@/views/list/search/SearchLayout'),
  SearchArticles: () => import('@/views/list/search/Article'),
  SearchProjects: () => import('@/views/list/search/Projects'),
  SearchApplications: () => import('@/views/list/search/Applications'),
  ProfileBasic: () => import('@/views/profile/basic'),
  ProfileAdvanced: () => import('@/views/profile/advanced/Advanced'),

  // result
  ResultSuccess: () => import(/* webpackChunkName: "result" */ '@/views/result/Success'),
  ResultFail: () => import(/* webpackChunkName: "result" */ '@/views/result/Error'),

  // exception
  Exception403: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403'),
  Exception404: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
  Exception500: () => import(/* webpackChunkName: "fail" */ '@/views/exception/500'),

  // account
  AccountCenter: () => import('@/views/account/center'),
  AccountSettings: () => import('@/views/account/settings/Index'),
  BasicSetting: () => import('@/views/account/settings/BasicSetting'),
  SecuritySettings: () => import('@/views/account/settings/Security'),
  CustomSettings: () => import('@/views/account/settings/Custom'),
  BindingSettings: () => import('@/views/account/settings/Binding'),
  NotificationSettings: () => import('@/views/account/settings/Notification'),

  RoleList: () => import('@/views/role/RoleList'),
  PowerInfo: () => import('@/views/role/PowerInfo'),
  RoleRangePower: () => import('@/views/role/RangePower'),
  GridAdminList: () => import('@/views/syueuser/UserList'),
  UseRangePower: () => import('@/views/syueuser/RangePower'),
  CenterAccountSettings: () => import('@/views/admincenter/Index'),
  CenterBasicSettings: () => import('@/views/admincenter/BasicSetting'),
  CenterNotificationSettings: () => import('@/views/admincenter/Notification'),
  CenterUserPassword: () => import('@/views/admincenter/UserPassword'),

  TestIconSelect: () => import('@/views/other/IconSelectorView'),
  TreeList: () => import('@/views/other/TreeList'),
  EditList: () => import('@/views/other/TableInnerEditList'),
  UserListw: () => import('@/views/other/UserList'),
  RoleListw: () => import('@/views/other/RoleList'),
  SystemRole: () => import('@/views/other/RoleList'),
  PermissionList: () => import('@/views/other/PermissionList'),
  RichText: () => import('@/views/other/RichText'),

  // 住户管理
  ResidentList: () => import('@/views/resident/ResidentList'),
  FocusMemberList: () => import('@/views/resident/FocusList'),
  AddFocusMember: () => import('@/views/resident/AddFocus'),
  CommunistList: () => import('@/views/special/CommunistList'),
  AddCommunist: () => import('@/views/special/AddCommunist'),
  VeteransManage: () => import('@/views/special/VeteransList'),
  AddVeterans: () => import('@/views/special/AddVeterans'),
  SpecialCommonList: () => import('@/views/special/SpecialList'),
  FocusControlList: () => import('@/views/resident/ControlList'),

  // 网格管理
  GridRoleList: () => import('@/views/gridrole/RoleList'),
  GridModulePower: () => import('@/views/gridrole/ModulePower'),
  GridRegionPower: () => import('@/views/gridrole/RegionPower'),
  GridUserList: () => import('@/views/gridrole/UserList'),

  // 房产管理
  GridCommunityList: () => import('@/views/home/CommunityList'),
  GridBuildingList: () => import('@/views/home/BuildingList'),
  GridUnitList: () => import('@/views/home/UnitList'),
  GridHouseList: () => import('@/views/home/HouseList'),

  // 用户管理
  GridMemberList: () => import('@/views/member/MemberList'),
  GridMemberListParent: () => import('@/views/member/MemberList'),
  GridVisitList: () => import('@/views/member/VisitList'),
  GridVisitListParent: () => import('@/views/member/VisitList'),
  GridMemberInfo: () => import('@/views/member/MemberInfo'),
  GridVisitMemberInfo: () => import('@/views/member/MemberInfo'),

  // 返乡管理
  GridbackHomeAppointment: () => import('@/views/back/AppointList'),
  GridBackHomeRecord: () => import('@/views/back/RecordList'),
  // 疫苗登录
  GridVaccinesList: () => import('@/views/vaccines/RecordList'),
  // 公告管理
  GridNoticeList: () => import('@/views/notice/NoticeList'),
  GridNoticeListParent: () => import('@/views/notice/NoticeList'),
  GridNoticeVerifyList: () => import('@/views/notice/VerifyList'),
  GridNoticeVerifyListParent: () => import('@/views/notice/VerifyList'),
  GridNoticeInfo: () => import('@/views/notice/NoticeInfo'),
  GridVerifyNoticeInfo: () => import('@/views/notice/NoticeInfo'),
  // 便民服务
  GridEventReportList: () => import('@/views/event/EventList'),
  GridMyEventList: () => import('@/views/event/MyEventList'),
  GridMemberSuggestList: () => import('@/views/event/SuggestList'),
  GridHelpTelephone: () => import('@/views/link/PhoneList'),
  GridHelpWebsite: () => import('@/views/link/WebsiteList'),
  GridHelpApply: () => import('@/views/link/ApplyList'),
  GridEventPublicHealth: () => import('@/views/event/HealthreportList'),
  GripMaintainReport: () => import('@/views/maintain/ReportList'),
  GridComplaintRecord: () => import('@/views/complaint/ReportList'),
  // 任务管理
  GridMissionList: () => import('@/views/mission/MissionList'),
  GridMissionListParent: () => import('@/views/mission/MissionList'),
  GridUserMissionList: () => import('@/views/mission/UserMissionList'),
  GridUserMissionListParent: () => import('@/views/mission/UserMissionList'),
  // 系统管理
  RotationList: () => import('@/views/system/RotationList'),
  ApplySiteSet: () => import('@/views/system/ApplySite'),
  // 'TestWork': () => import(/* webpackChunkName: "TestWork" */ '@/views/dashboard/TestWork')
  // 社区活动
  CommunityActivitiesList: () => import('@/views/article/ArticleList'),
  AddCommunityActivities: () => import('@/views/article/AddArticle'),
  // 社区文章
  CommunityArticleList: () => import('@/views/article/EssayList'),
  AddCommunityArticle: () => import('@/views/article/AddEssay'),
  // 申请管理
  DisabilityList: () => import('@/views/application/DisabilityList'),
  AddDisability: () => import('@/views/application/AddDisability'),
  SpecialList: () => import('@/views/application/SpecialList'),
  AddSpecial: () => import('@/views/application/AddSpecial'),
  VolunteerList: () => import('@/views/application/VolunteerList'),
  AddVolunteer: () => import('@/views/application/AddVolunteer'),
  YanglaoList: () => import('@/views/application/YanglaoList'),
  AddYanglao: () => import('@/views/application/AddYanglao'),
  LongCareServices: () => import('@/views/application/ChanghuList'),
  AddChangHu: () => import('@/views/application/AddChanghu'),
  // 网址配置
  WebsiteConfigList: () => import('@/views/webconfig/ConfigList'),
  // 政务管理
  GovernmentWorkList: () => import('@/views/work/WorkList'),
  AddGovernmentWork: () => import('@/views/work/AddWork'),
  GovernmentRecordList: () => import('@/views/work/RecordList'),
  // 走访管理
  InterviewList: () => import('@/views/visit/VisitList'),
  AddInterview: () => import('@/views/visit/AddVisit'),
  // 上访记录
  ShangFangRecordList: () => import('@/views/shangfang/RecordList'),
  AddShangFangRecord: () => import('@/views/shangfang/AddRecord'),
  // 数据维护
  DataMaintainLog: () => import('@/views/stickup/Log'),
  // 哨点管理
  GridEventList: () => import('@/views/grid/EventList'),
  GridSignInList: () => import('@/views/grid/SignInList'),
  GridClassifyList: () => import('@/views/grid/ClassifyList'),
  GridPointList: () => import('@/views/grid/PointList')
}

// 前端未找到页面路由（固定不用改）
const notFoundRouter = {
  path: '*',
  redirect: '/404',
  hidden: true
}

// 根级菜单
const rootRouter = {
  key: '',
  name: 'index',
  path: '',
  component: 'BasicLayout',
  redirect: '/dashboard',
  meta: {
    title: '首页'
  },
  children: []
}

/**
 * 动态生成菜单
 * @param token
 * @returns {Promise<Router>}
 */
export const generatorDynamicRouter = token => {
  return new Promise((resolve, reject) => {
    loginService
      .getCurrentUserNav(token)
      .then(res => {
        console.log('generatorDynamicRouter response:', res)
        const { result } = res
        const menuNav = []
        const childrenNav = []
        //      后端数据, 根级树数组,  根级 PID
        listToTree(result, childrenNav, 0)
        rootRouter.children = childrenNav
        menuNav.push(rootRouter)
        console.log('menuNav', menuNav)
        const routers = generator(menuNav)
        routers.push(notFoundRouter)
        console.log('routers', routers)
        resolve(routers)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (routerMap, parent) => {
  return routerMap.map(item => {
    const { title, show, hideChildren, hiddenHeaderContent, target, icon } = item.meta || {}
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path: item.path || `${(parent && parent.path) || ''}/${item.key}`,
      // 路由名称，建议唯一
      name: item.name || item.key || '',
      // 该路由对应页面的 组件 :方案1
      // component: constantRouterComponents[item.component || item.key],
      // 该路由对应页面的 组件 :方案2 (动态加载)
      component: constantRouterComponents[item.component || item.key] || (() => import(`@/views/${item.component}`)),

      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: title,
        icon: icon || undefined,
        hiddenHeaderContent: hiddenHeaderContent,
        target: target,
        permission: item.name
      }
    }
    // 是否设置了隐藏菜单
    if (show === false) {
      currentRouter.hidden = true
    }
    // 是否设置了隐藏子菜单
    if (hideChildren) {
      currentRouter.hideChildrenInMenu = true
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/')
    }
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect)
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter)
    }
    return currentRouter
  })
}

/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
const listToTree = (list, tree, parentId) => {
  list.forEach(item => {
    // 判断是否为父级菜单
    if (item.parentId === parentId) {
      const child = {
        ...item,
        key: item.key || item.name,
        children: []
      }
      // 迭代 list， 找到当前菜单相符合的所有子菜单
      listToTree(list, child.children, item.id)
      // 删掉不存在 children 值的属性
      if (child.children.length <= 0) {
        delete child.children
      }
      // 加入到树中
      tree.push(child)
    }
  })
}
