import storage from 'store'
import expirePlugin from 'store/plugins/expire'
import { getInfo, logout, loginUser } from '@/api/login'
import { userNoticeCount, userNoticeList } from '@/api/notice'
import { ACCESS_TOKEN, APPLYID, ROLEID, GRIDROLEID } from '@/store/mutation-types'
import { welcome } from '@/utils/util'

storage.addPlugin(expirePlugin)
const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {},
    applyId: -1,
    roleId: 0,
    isResetPass: 0,
    gridRoleId: 0
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_APPLYID: (state, applyId) => {
      state.applyId = applyId
    },
    SET_ROLEID: (state, roleId) => {
      state.roleId = roleId
    },
    SET_GRIDROLEID: (state, gridRoleId) => {
      state.gridRoleId = gridRoleId
    },
    SET_ISRESETPASS: (state, isResetPass) => {
      state.isResetPass = isResetPass
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginUser(userInfo).then(response => {
          const result = response.result
          storage.set(ACCESS_TOKEN, result.token, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
          commit('SET_TOKEN', result.token)
          storage.set(APPLYID, result.applyId, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
          commit('SET_APPLYID', result.applyId)
          storage.set(ROLEID, result.userRoleId, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
          commit('SET_ROLEID', result.userRoleId)
          storage.set(GRIDROLEID, result.gridRoleId, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
          commit('SET_GRIDROLEID', result.gridRoleId)
          commit('SET_ISRESETPASS', result.isResetPass)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const result = response.result

          if (result.role && result.role.permissions.length > 0) {
            const role = result.role
            role.permissions = result.role.permissions
            role.permissions.map(per => {
              if (per.actionEntitySet != null && per.actionEntitySet.length > 0) {
                const action = per.actionEntitySet.map(action => { return action.action })
                per.actionList = action
              }
            })
            role.permissionList = role.permissions.map(permission => { return permission.permissionId })
            commit('SET_ROLES', result.role)
            commit('SET_INFO', result)
          } else {
            reject(new Error('getInfo: roles must be a non-null array !'))
          }

          commit('SET_NAME', { name: result.name, welcome: welcome() })
          commit('SET_AVATAR', result.avatar)
          commit('SET_ROLEID', result.userRoleId)
          commit('SET_GRIDROLEID', result.gridRoleId)
          commit('SET_APPLYID', result.applyId)
          commit('SET_ISRESETPASS', result.isResetPass)
          // commit('SET_ISRESETPASS', 0)

          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          storage.remove(ACCESS_TOKEN)
          commit('SET_APPLYID', -1)
          storage.remove(APPLYID)
          commit('SET_ROLEID', 0)
          storage.remove(ROLEID)
          commit('SET_GRIDROLEID', 0)
          storage.remove(GRIDROLEID)
          commit('SET_ISRESETPASS', 0)
          resolve()
        }).catch((err) => {
          console.log('logout fail:', err)
          // resolve()
        }).finally(() => {
        })
      })
    },

    UserNoticeCountApi () {
      return new Promise((resolve) => {
        userNoticeCount({ isReaded: 0 }).then(response => {
          resolve(response)
        }).catch((err) => {
          console.log('userNoticeCountApi fail:', err)
          // resolve()
        }).finally(() => {
        })
      })
    },

    UserNoticeListApi () {
      return new Promise((resolve) => {
        userNoticeList({ isReaded: 0, pageNo: 1, pageSize: 10 }).then(response => {
          resolve(response)
        }).catch((err) => {
          console.log('userNoticeListApi fail:', err)
          // resolve()
        }).finally(() => {
        })
      })
    }

  }
}

export default user
