import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { ACCESS_TOKEN, APPLYID, AUTHORIZATION, GRIDROLEID, ROLEID } from '@/store/mutation-types'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  // baseURL: process.env.VUE_APP_API_BASE_URL,
  // baseURL: '/api',
  timeout: 15000, // 请求超时时间
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
    // 'Access-Control-Request-Headers': 'origin, x-requested-with,token'
  }
})

// 异常拦截处理器
const errorHandler = (error) => {
  console.log('errorHandler', error.response)
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      notification.error({
        message: error.response.data.errorMsg,
        description: error.response.data.errorMsg
      })

      setTimeout(() => {
        location.href = '/exception/403'
      }, 1500)
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'token失效',
        description: error.response.data.errorMsg
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers[ACCESS_TOKEN] = token
    config.headers[AUTHORIZATION] = 'Bearer ' + token
  } else {
    config.headers[ACCESS_TOKEN] = ''
    config.headers[AUTHORIZATION] = ''
  }

  // 应用id
  const applyId = storage.get(APPLYID)
  if (applyId) {
    config.headers[APPLYID] = applyId
  } else {
    config.headers[APPLYID] = -1
  }
  // 角色id
  const roleId = storage.get(ROLEID)
  if (applyId) {
    config.headers[ROLEID] = roleId
  } else {
    config.headers[ROLEID] = 0
  }

  const gridRoleId = storage.get(GRIDROLEID)
  if (gridRoleId) {
    config.headers[GRIDROLEID] = gridRoleId
  } else {
    config.headers[GRIDROLEID] = 0
  }

  config.data = qs.stringify(config.data)
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  // token 失效
  if (response.data.errorCode === 401) {
    notification.error({
      message: 'token失效',
      description: response.data.errorMsg
    })
    store.dispatch('Logout').then(() => {
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    })
  }
  // 没有权限
  if (response.data.errorCode === 403) {
    location.href = '/exception/403'
  }
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
