<template>
  <a-popover
    v-model="visible"
    trigger="click"
    placement="bottomLeft"
    overlayClassName="header-notice-wrapper"
    :getPopupContainer="() => $refs.noticeRef.parentElement"
    :autoAdjustOverflow="true"
    :arrowPointAtCenter="true"
    :overlayStyle="{ width: '300px', top: '50px' }"
  >
    <template slot="content">
      <a-spin :spinning="loading">
        <a-tabs>
          <a-tab-pane tab="消息" key="1">
            <a-list :locale="locale">
              <a-list-item :key="index" v-for="(item, index) in dataList">
                <a-list-item-meta :description="item.publishTime">
                  <a slot="title" @click="gotoNotice()">
                    {{ item.title }}
                  </a>
                </a-list-item-meta>
              </a-list-item>
              <!--a-list-item>
                <a-list-item-meta title="你收到了 14 份新周报" description="一年前">
                  <a-avatar style="background-color: white" slot="avatar" src="https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png"/>
                </a-list-item-meta>
              </a-list-item>
              <a-list-item>
                <a-list-item-meta title="你推荐的 曲妮妮 已通过第三轮面试" description="一年前">
                  <a-avatar style="background-color: white" slot="avatar" src="https://gw.alipayobjects.com/zos/rmsportal/OKJXDXrmkNshAMvwtvhu.png"/>
                </a-list-item-meta>
              </a-list-item>
              <a-list-item>
                <a-list-item-meta title="这种模板可以区分多种通知类型" description="一年前">
                  <a-avatar style="background-color: white" slot="avatar" src="https://gw.alipayobjects.com/zos/rmsportal/kISTdvpyTAhtGxpovNWd.png"/>
                </a-list-item-meta>
              </a-list-item-->
            </a-list>
          </a-tab-pane>
          <!--a-tab-pane tab="消息" key="2">
            123
          </a-tab-pane>
          <a-tab-pane tab="待办" key="3">
            123
          </a-tab-pane-->
        </a-tabs>
      </a-spin>
    </template>
    <span @click="fetchNotice" class="header-notice" ref="noticeRef" style="padding: 0 18px">
      <a-badge :count="noticeCount">
        <a-icon style="font-size: 16px; padding: 4px" type="bell" />
      </a-badge>
    </span>
  </a-popover>
</template>

<script>

export default {
  name: 'HeaderNotice',
  props: {
    noticeCount: {
      type: Number,
      default: 12
    }
  },
  data () {
    return {
      loading: false,
      visible: false,
      dataList: [],
      locale: { emptyText: '暂无消息' }
    }
  },
  created () {
  },
  methods: {
    fetchNotice () {
      if (!this.visible) {
        /* this.loading = true
        setTimeout(() => {
          this.loading = false
        }, 2000) */
        this.userNoticeListApi()
      } else {
        this.loading = false
      }
      this.visible = !this.visible
    },
    userNoticeListApi () {
      const _this = this
      _this.loading = true
      _this.$store.dispatch('UserNoticeListApi').then(res => {
        if (res.errorCode === 0) {
          _this.dataList = res.result.data
          _this.loading = false
        }
        console.log('userNoticeCountApi', res)
      })
    },
    gotoNotice () {
      this.$router.push({ path: '/admincenter/notification' })
      this.visible = false
    }
  }
}
</script>

<style lang="css">
  .header-notice-wrapper {
    top: 50px !important;
  }
</style>
<style lang="less" scoped>
  .header-notice{
    display: inline-block;
    transition: all 0.3s;

    span {
      vertical-align: initial;
    }
  }
</style>
