<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view v-if="isRouterAlive"></router-view>
      <!--router-view :key="$route.fullPath"></router-view-->
    </div>
  </a-config-provider>
</template>

<script>
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import { i18nRender } from '@/locales'

export default {
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  computed: {
    locale () {
      // 只是为了切换语言时，更新标题
      const { title } = this.$route.meta
      title && (setDocumentTitle(`${i18nRender(title)} - ${domTitle}`))

      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  },
  created () {
    // this.reload()
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val && oldVal) {
          if (val.path === oldVal.path && val.fullPath !== oldVal.fullPath) {
            // this.reload()
          }
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  }
}
</script>
