<template>
  <global-footer class="footer custom-render" style="margin: 0px;">
    <template v-slot:links>
      <a>copyright@大道简器信息咨询（唐山）有限公司</a>
    </template>
    <template v-slot:copyright>
      备案号：<a href="https://beian.miit.gov.cn" target="_blank">冀ICP备17017489号-2</a>
    </template>
  </global-footer>
</template>

<script>
import { GlobalFooter } from '@ant-design-vue/pro-layout'

export default {
  name: 'ProGlobalFooter',
  components: {
    GlobalFooter
  }
}
</script>
<style lang="less" scoped>
</style>
